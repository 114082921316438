<template>
  <v-container fluid>
    <div v-if="flashDeals.length">
      <v-card elevation="5" v-for="item in flashDeals" :key="item.id">
        <v-card-title>Flash Deals</v-card-title>
        <v-card-text class="mb-3">
          <v-row>
            <v-col lg="6" class="flash-deals">
              <h3>Title</h3>
              <p>{{ item.title }}</p>
              <h3>Description</h3>
              <p>{{ item.description }}</p>
              <h3>Start Date</h3>
              <p>{{ item.start_date }}</p>
              <h3>End Date</h3>
              <p>{{ item.end_date }}</p>
            </v-col>
            <v-col lg="6" class="flash-deals">
              <h3>Rules To Participate</h3>
            <p v-html="item.rules"> {{item.rules}}</p>
              <h3 class="pt-4 pb-2">Interested in this event</h3>
              <v-btn color="error" @click="showDialog(item.id)">Participate</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card elevation="5">
        <v-card-title
        >Participation Details
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
          ></v-text-field>
        </v-card-title>


        <v-data-table
            :headers="simpleHeader"
            :items="getAllFlasDealProducts"
            :search="search"
        >


        </v-data-table>


      </v-card>
    </div>
    <div v-else>
      <v-card class="mt-4" elevation="5">
        <v-card-text>
          <h3 class="font-bold text-center mb-4">There are no any Flash deals opened yet. Please check it back later.</h3>
          <p class="text-black">Flash Deal Program is launched by the Doormeet on special occasion throughout the year. Once Doormeet launch the Flash deal program, it will be displayed here.</p>
          <p class="text-black">Once Launched, It will be displayed here and You need to participate into that Flash Deals event.</p>
          <h5 class="font-bold">Contact Doormeet if you have any queries related to the Flash Deals Program.</h5>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          Participate
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-autocomplete
                v-model="product.product_id"
                :items="getProductName"
                item-text="name"
                :rules="required"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="Choose Product"
            ></v-autocomplete>
            <v-text-field
                v-model="product.quantity"
                outlined
                :rules="numberValidation"
                dense
                min="1"
                label="Add Quantity"
                type="number"
            ></v-text-field>
            <v-select
                v-model="product.discount_type"
                :items="discount_type"
                label="Discount Type"
                :rules="required"
                dense
                min="1"
                outlined
            ></v-select>
            <v-text-field
                v-model="product.discount"
                outlined
                dense
                min="0"
                max="100"
                label="Discount Amount/Percentage"
                type="number"
            ></v-text-field>
            <v-btn color="primary" @click="store(item)"
            >Submit
            </v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      search:'',
      flashDeals: {},
      flash_deal_id:null,
      flashDealProducts:[],
      valid:true,
      dialog: false,
      products: [],
      product: {discount:'',flash_deal_id:null,product_id:null},
      unit_price: '',
      discount_type: ['Flat Cash', 'Percentage'],
      items: ['Samsung Galaxy A70', 'Samsung Galaxy A32', 'Samsung Galaxy A12', 'Samsung Galaxy Note'],
      required: [
        v => !!v || 'This Field is required',

      ],
      numberValidation:[
        v => !!v || 'This Field is required',
        v => !isNaN(v) || 'Quantity Must be a Number',
      ],
      simpleHeader: [
        { text: "SN", align: "left", value: "sn" },
        {
          text: "Flash Deal Title",
          align: "left",
          value: "title",
        },
        {
          text: "Product",
          align: "left",
          value: "product",
        },
        { text: "Quantity", align: "left", value: "quantity" },
        {
          text: "Discount Type",
          align: "left",
          value: "discount_type",
        },
        { text: "Discount Value", align: "left", value: "discount_value" },


      ],
    }
  },

  computed: {
    ...mapGetters({
      getUpcomingFlashDeals: "getFlashDeals", getAllProducts: "getProducts"
    }),

    getProductName() {
      return this.products.map((item) => ({
        name: item.name,
        id: item.id
      }));

    },
    getAllFlasDealProducts(){
      return this.flashDealProducts.map((item,index)=>({
        sn:index+1,
        title:item.flashdeal&&item.flashdeal.title,
        discount_type:item.discount_type,
        quantity:item.quantity,
        product:item.products&&item.products.name,
        discount_value:item.discount_type==="Percentage"?item.discount+'%' :'Rs.'+item.discount,
      }))
    }

  },

  methods: {
    ...mapActions(["getFlashDeals", "getProducts","storeFlashDealProduct",'getFlashDealProducts']),

    store() {
      this.storeFlashDealProduct(this.product).then(response => {
        console.log(response.status);
      })
          .catch(error => {
            console.log(error.status);
            alert(error);
          });
      this.dialog = false;
      this.product={};

    },
    showDialog(id){
     this.product.flash_deal_id=id;
      this.dialog=true;
    }

  },

  mounted() {
    if (!this.$store.state.flashDeals.length) {
      this.getFlashDeals().then((response) => {
        this.flashDeals = response;
      });

    } else {
      this.flashDeals = this.$store.state.flashDeals;
      this.products = this.$store.state.products;

    }
    if (!this.$store.state.products.length) {
      this.getProducts().then((response) => {
        this.products = response;

      });
    }
    else {

      this.products = this.$store.state.products;

    }
    if(!this.$store.state.flashDealProducts.length){
      this.getFlashDealProducts().then((response) => {
        this.flashDealProducts = response;
      });
    }
    else{
      this.flashDealProducts=this.$store.state.flashDealProducts;
    }


  },


}
</script>

<style lang="css">
.flash-deals h3 {
  color: rgba(0, 0, 0, 0.8) !important;
}
.text-black{
  color: rgba(0, 0, 0) !important;
}

</style>